/* @flow */

import * as React from 'react';

import type {GetAccountBilling_accountBilling_currentPlan as AccountPlan} from 'nutshell-graphql-types';

import {LoadingPage} from '../../loading-page';
import {ErrorState} from '../../error-state';
import {TableSectionHeader} from './table-section-header';
import {DescriptionCell} from './description-cell';
import {PriceCell} from './price-cell';
import {getAddOnsIncludedInPlan, getPlanDetailText} from './helpers';

import './billing-summary.css';

type Props = {
    plan: ?AccountPlan,
    isLoading: boolean,
    includeHeaderLinks?: boolean,
    isPurchasing?: boolean,
    errorMessage: ?string,
};

export function BillingSummary(props: Props) {
    const crmPlan = props.plan && props.plan.crm;
    const engagementPlan = props.plan && props.plan.inbox;
    const marketingSuitePlan = props.plan && props.plan.marketingSuite;
    const marketing = props.plan && props.plan.marketing;
    const includedAddOns = getAddOnsIncludedInPlan(props.plan);

    const isBilledAnnually = crmPlan && crmPlan.cycleText === 'ANNUALLY';
    const shouldShowSubtotal =
        props.plan && props.plan.totalMonthDisplay !== props.plan.subtotalMonthDisplay;

    const headerRow = (
        <thead styleName='header'>
            <tr>
                <td>Description</td>
                <td>Qty</td>
                <td>Price</td>
                <td>Amount</td>
            </tr>
        </thead>
    );

    const loadingState = (
        <tbody styleName='body'>
            <tr>
                <td colSpan='4'>
                    <LoadingPage />
                </td>
            </tr>
        </tbody>
    );

    const errorState = (
        <tbody styleName='body'>
            <tr>
                <td colSpan='4'>
                    <ErrorState errorMessage={props.errorMessage} size='small' />
                </td>
            </tr>
        </tbody>
    );

    return (
        <table styleName='table'>
            <TableSectionHeader
                linkProps={
                    props.includeHeaderLinks
                        ? {
                              to: '/setup/billing/manage',
                              text: 'Manage plan',
                          }
                        : undefined
                }
                title={props.isPurchasing ? 'Selected plan' : 'Your plan'}
            />
            {headerRow}
            {props.isLoading && loadingState}
            {props.errorMessage && errorState}
            {!props.isLoading && !props.errorMessage && props.plan && (
                <>
                    {crmPlan && (
                        <tbody styleName='body'>
                            <tr>
                                <DescriptionCell
                                    description={crmPlan.nameDisplay}
                                    detail={getPlanDetailText(crmPlan)}
                                    coupons={crmPlan.coupon ? [crmPlan.coupon] : []}
                                    customRow={
                                        isBilledAnnually && (
                                            <div styleName='info-pill'>
                                                Billed annually - saving 15%
                                            </div>
                                        )
                                    }
                                />
                                <td>{crmPlan.quantity}</td>
                                <PriceCell
                                    stickerPrice={crmPlan.stickerUnitAmountDisplay}
                                    finalPrice={crmPlan.unitAmountDisplay}
                                />
                                <PriceCell finalPrice={crmPlan.totalDisplay} />
                            </tr>
                        </tbody>
                    )}
                    {engagementPlan && (
                        <tbody styleName='body'>
                            <tr>
                                <DescriptionCell
                                    description={engagementPlan.nameDisplay}
                                    detail={`${engagementPlan.quantity} seats`}
                                    coupons={engagementPlan.coupon ? [engagementPlan.coupon] : []}
                                />
                                <td>{engagementPlan.quantity}</td>
                                <PriceCell
                                    stickerPrice={engagementPlan.stickerUnitAmountDisplay}
                                    finalPrice={engagementPlan.unitAmountDisplay}
                                />
                                <PriceCell finalPrice={engagementPlan.totalDisplay} />
                            </tr>
                        </tbody>
                    )}
                    {marketingSuitePlan && (
                        <tbody styleName='body'>
                            <tr>
                                <DescriptionCell
                                    description={marketingSuitePlan.nameDisplay}
                                    detail={`Entire company`}
                                    coupons={[
                                        ...(marketingSuitePlan.coupon
                                            ? [marketingSuitePlan.coupon]
                                            : []),
                                    ]}
                                />
                                <td></td>
                                <PriceCell
                                    stickerPrice={marketingSuitePlan.stickerUnitAmountDisplay}
                                    finalPrice={marketingSuitePlan.unitAmountDisplay}
                                />
                                <PriceCell finalPrice={marketingSuitePlan.totalDisplay} />
                            </tr>
                            {marketing && (
                                <>
                                    <tr styleName='email-marketing-row'>
                                        <div styleName='email-marketing-description'>
                                            <DescriptionCell
                                                description='Email marketing'
                                                detail={getPlanDetailText(marketing)}
                                                coupons={marketing.coupon ? [marketing.coupon] : []}
                                                leftBorder={true}
                                            />
                                        </div>
                                        <td></td>
                                        <PriceCell
                                            stickerPrice={marketing.stickerUnitAmountDisplay}
                                            finalPrice={marketing.unitAmountDisplay}
                                        />
                                        <PriceCell finalPrice={marketing.totalDisplay} />
                                    </tr>
                                </>
                            )}
                        </tbody>
                    )}
                    {includedAddOns.length > 0 && (
                        <TableSectionHeader
                            linkProps={
                                props.includeHeaderLinks
                                    ? {
                                          to: '/setup/billing/manage#add-ons',
                                          text: 'Manage add-ons',
                                      }
                                    : undefined
                            }
                            title={props.isPurchasing ? 'Selected add-ons' : 'Your add-ons'}
                        />
                    )}
                    {includedAddOns.map((addOn) => (
                        <tbody styleName='body' key={addOn.planCode}>
                            <tr>
                                <DescriptionCell
                                    description={addOn.nameDisplay}
                                    detail={getPlanDetailText(addOn)}
                                    coupons={addOn.coupon ? [addOn.coupon] : []}
                                />
                                <td></td>
                                <PriceCell
                                    stickerPrice={addOn.stickerUnitAmountDisplay}
                                    finalPrice={addOn.unitAmountDisplay}
                                />
                                <PriceCell finalPrice={addOn.totalDisplay} />
                            </tr>
                        </tbody>
                    ))}
                    {/* This looks backwards, but footers display in reverse order! */}
                    <tfoot styleName='footer'>
                        <tr>
                            <DescriptionCell description='Monthly total' />
                            {/* Next 2 cells purposely empty */}
                            <td></td>
                            <td></td>
                            {/* $FlowIgnore checked above */}
                            <PriceCell finalPrice={props.plan.totalMonthDisplay} />
                        </tr>
                    </tfoot>
                    {shouldShowSubtotal && (
                        <tfoot styleName='footer'>
                            <tr>
                                <DescriptionCell
                                    description='Subtotal'
                                    coupons={props.plan ? props.plan.accountCoupons : []}
                                />
                                {/* Next 2 cells purposely empty */}
                                <td></td>
                                <td></td>
                                <PriceCell
                                    // $FlowIgnore checked above
                                    finalPrice={props.plan.subtotalMonthDisplay}
                                    // $FlowIgnore checked above
                                    discount={props.plan.accountDiscountDisplay}
                                />
                            </tr>
                        </tfoot>
                    )}
                </>
            )}
        </table>
    );
}
