/* @flow */

import * as React from 'react';
import {Link as RoutedLink} from 'react-router-dom';

import {Routing} from 'nutshell-core/routing';

import {Body} from '../../typography';
import {Link} from '../../link';
import {ErrorMessage} from '../../form/error-message';
import {
    EmailMarketingSpeakerPill,
    RevenueBoosterPill,
    NutshellIQPill,
    LandingPagesPill,
    InboxPill,
    SmsCreditsPill,
    QuotesPill,
} from '../../acorn-pill';
import type {Plan, Product, AddOn, SelectedAddOn, AddOns} from '../types';
import {
    MARKETING_HASH,
    REVENUE_BOOSTER_HASH,
    NUTSHELL_IQ_HASH,
    LANDING_PAGES_HASH,
    INBOX_HASH,
    SMS_CAMPAIGNS_HASH,
    QUOTES_HASH,
} from '../consts';

export const CUSTOM_PLAN_CODE = 'custom';
export const DEFAULT_INBOX_QUANTITY = 3;

export function getPlanToDisplay(
    plans: Plan[],
    selectedPlanCode: ?string,
    defaultPlanCode: ?string
): ?Plan {
    let planToDisplay = plans.find((plan) => plan.code === selectedPlanCode);

    if (!planToDisplay) {
        planToDisplay = plans.find((plan) => plan.code === defaultPlanCode);
    }

    return planToDisplay;
}

export function getProductPill(product: Product): ?React.Node {
    switch (product) {
        case 'MARKETING':
            return <EmailMarketingSpeakerPill height={28} />;
        case 'NUTSHELL_IQ':
            return <NutshellIQPill height={28} />;
        case 'REVENUE_BOOSTER':
            return <RevenueBoosterPill height={28} />;
        case 'LANDING_PAGES':
            return <LandingPagesPill height={28} />;
        case 'INBOX':
            return <InboxPill height={30} />;
        case 'SMS_CAMPAIGNS':
            return <SmsCreditsPill height={30} width={150} />;
        case 'QUOTES':
            return <QuotesPill height={30} />;
        default:
            return null;
    }
}

export function getLearnMoreLinkHref(product: Product): string {
    switch (product) {
        case 'REVENUE_BOOSTER':
            return 'https://support.nutshell.com/en/articles/8428989-scheduler-plan-schedule-and-manage-meetings';
        case 'NUTSHELL_IQ':
            return 'https://support.nutshell.com/en/collections/11432306-nutshell-iq';
        case 'LANDING_PAGES':
            return 'https://support.nutshell.com/en/articles/8790094-landing-pages-create-effective-landing-pages';
        case 'INBOX':
            return 'https://support.nutshell.com/en/articles/9674420-nutshell-sms-how-to-send-and-manage-texts';
        case 'SMS_CAMPAIGNS':
            return 'https://support.nutshell.com/en/articles/9674420-nutshell-sms-how-to-send-and-manage-texts';
        case 'QUOTES':
            return 'https://support.nutshell.com/en/articles/10616834-set-up-and-use-nutshell-quotes-to-create-share-and-manage-quotes-for-your-leads';
        default:
            return '';
    }
}

export function getAddOnInfo(addOn: AddOn, selectedAddOns: SelectedAddOn[]) {
    let plans = addOn.plans;
    const product = plans[0].product;

    if (typeof addOn.contactUsEndCap === 'number') {
        const customPlan = createCustomPlan(product, addOn.contactUsEndCap, plans[0]);
        plans = [...plans, customPlan];
    }

    const selectedAddOn = selectedAddOns.find(
        (selectedAddOnObj) => selectedAddOnObj.product === product
    );
    const activeAddOn = addOn.plans.find((plan) => plan.isActive);

    const selectedPlanCode = getSelectedPlanCode(
        selectedAddOn && selectedAddOn.planCode,
        activeAddOn && activeAddOn.code
    );

    const isUserQuantityBased = addOn.isUserQuantityBased;
    const selectedQuantity = isUserQuantityBased
        ? getSelectedQuantity(selectedAddOn, activeAddOn)
        : null;
    const userQuantityTerm = product === 'INBOX' ? 'SMS' : undefined;

    let infoMessage;
    let errorMessage;
    let defaultCode = addOn.defaultCode;
    const defaultQuantity = null;
    if (addOn.__typename === 'PlanGroupMarketing') {
        const numMarketingContacts = addOn.numMarketingContacts;
        infoMessage = getMarketingInfoMessage(numMarketingContacts);
        defaultCode = getDefaultMarketingCode(defaultCode, numMarketingContacts, plans);

        errorMessage = getMarketingErrorMessage(
            plans,
            selectedPlanCode,
            numMarketingContacts,
            addOn.numMarketingContactsAllowedOverride
        );
    }

    return {
        plans,
        product,
        userQuantityTerm,
        selectedQuantity,
        defaultCode,
        defaultQuantity,
        isUserQuantityBased,
        isEnabled: Boolean(selectedAddOn),
        selectedPlanCode,
        infoMessage,
        errorMessage,
    };
}

function createCustomPlan(product: Product, quantity: number, planTemplate: Plan): Plan {
    return {
        __typename: 'Plan',
        product,
        code: CUSTOM_PLAN_CODE,
        description: planTemplate.description,
        price: '--',
        stickerPrice: '--',
        productQuantity: quantity,
        cycleText: 'MONTHLY',
        isActive: false,
        isLegacy: false,
        quantity: 0,
        planHighlights: planTemplate.planHighlights,
        priorFeaturesText: '',
        subtitleText: '',
        coupon: null,
        nameDisplay: '',
        name: '',
        priceUnit: null,
        isFake: false,
        shortenedName: '',
    };
}

function getSelectedPlanCode(selectedCode?: string, activeCode?: string): ?string {
    return selectedCode || activeCode || null;
}

/**
 * Gets the quantity that we want to show. If a user has made a recent modification (which is reflected in selectedAddOn),
 * we want to show that. Otherwise, we want to show the quantity of the active plan (if it exists).
 */
function getSelectedQuantity(selectedAddOn: ?SelectedAddOn, activePlan: ?Plan): ?number {
    if (selectedAddOn && selectedAddOn.quantity) {
        return selectedAddOn.quantity;
    }

    if (activePlan) {
        return activePlan.quantity;
    }

    return null;
}

function getMarketingInfoMessage(numMarketingContacts: number) {
    const filter = Routing.param({
        filter: {
            marketingStatus: {data: [{data: '-'}]},
        },
    });

    const linkTo = `/people?${filter}&persist=false`;

    return (
        <Body>
            You have{' '}
            <Link as={RoutedLink} newTab={true} variant='primary' to={linkTo}>
                {numMarketingContacts.toLocaleString()} marketing{' '}
                {numMarketingContacts === 1 ? 'contact' : 'contacts'}
            </Link>
        </Body>
    );
}

function getDefaultMarketingCode(defaultCode: string, numMarketingContacts: number, plans: Plan[]) {
    const defaultPlan = plans.find((plan) => plan.code === defaultCode);
    if (defaultPlan && defaultPlan.productQuantity < numMarketingContacts) {
        for (const plan of plans) {
            if (plan.productQuantity >= numMarketingContacts || plan.code === CUSTOM_PLAN_CODE) {
                return plan.code;
            }
        }
    }

    return defaultCode; /* Return the original default code if no suitable plan is found */
}

export function getMarketingErrorMessage(
    plans: Plan[],
    selectedPlanCode: ?string,
    numMarketingContacts: ?number,
    numMarketingContactsAllowed: ?number
): ?React.Node {
    const activePlan = plans.find((plan) => plan.isActive);
    const isSelectingOtherPlan = Boolean(
        activePlan && selectedPlanCode && selectedPlanCode !== activePlan.code
    );
    const hasOverride = typeof numMarketingContactsAllowed === 'number';
    const isTryingToChangeOverriddenPlan = hasOverride && isSelectingOtherPlan;

    const isBelowMarketingContactLimit =
        !hasOverride && (!activePlan || isSelectingOtherPlan)
            ? checkIsBelowMarketingContactLimit(plans, selectedPlanCode, numMarketingContacts)
            : false;

    if (isBelowMarketingContactLimit || isTryingToChangeOverriddenPlan) {
        return (
            <ErrorMessage>
                {isBelowMarketingContactLimit
                    ? 'This plan is below your number of marketing contacts'
                    : 'Please contact support to change your marketing plan'}
            </ErrorMessage>
        );
    }

    return null;
}

function checkIsBelowMarketingContactLimit(
    marketingPlans: Plan[],
    selectedPlanCode: ?string,
    numMarketingContacts: ?number
) {
    if (
        !selectedPlanCode ||
        selectedPlanCode === CUSTOM_PLAN_CODE ||
        typeof numMarketingContacts !== 'number'
    ) {
        return false;
    }

    const selectedPlan = marketingPlans.find((plan) => plan.code === selectedPlanCode);

    return selectedPlan && selectedPlan.productQuantity < numMarketingContacts;
}

export function getInboxErrorMessage(quantity: number, numEnabledLicenses: number): ?React.Node {
    return quantity < numEnabledLicenses ? (
        <ErrorMessage>
            This plan is below your current number of enabled licenses ({numEnabledLicenses})
        </ErrorMessage>
    ) : null;
}

export function getAddOnTitle(product: Product) {
    switch (product) {
        case 'MARKETING':
            return 'Email Marketing';
        case 'REVENUE_BOOSTER':
            return 'Revenue Booster';
        case 'NUTSHELL_IQ':
            return 'Nutshell IQ';
        case 'LANDING_PAGES':
            return 'Landing Pages';
        case 'INBOX':
            return 'SMS';
        case 'SMS_CAMPAIGNS':
            return 'SMS Credits';
        case 'QUOTES':
            return 'Quotes';
        default:
            return null;
    }
}

export function getAddOnLink(product: Product) {
    const baseLink = '/setup/billing/manage';
    let hash = '';
    switch (product) {
        case 'MARKETING':
            hash = MARKETING_HASH;
            break;
        case 'REVENUE_BOOSTER':
            hash = REVENUE_BOOSTER_HASH;
            break;
        case 'NUTSHELL_IQ':
            hash = NUTSHELL_IQ_HASH;
            break;
        case 'LANDING_PAGES':
            hash = LANDING_PAGES_HASH;
            break;
        case 'INBOX':
            hash = INBOX_HASH;
            break;
        case 'SMS_CAMPAIGNS':
            hash = SMS_CAMPAIGNS_HASH;
            break;
        case 'QUOTES':
            hash = QUOTES_HASH;
            break;
    }

    return `${baseLink}${hash}`;
}

export function createSelectedAddOnObj(hash: string, addOns: AddOns): ?SelectedAddOn {
    let selectedAddOnObj = null;

    if (hash === MARKETING_HASH) {
        const defaultCode = getDefaultMarketingCode(
            addOns.marketing.defaultCode,
            addOns.marketing.numMarketingContacts,
            addOns.marketing.plans
        );
        selectedAddOnObj = {product: 'MARKETING', planCode: defaultCode, quantity: null};
    } else if (hash === REVENUE_BOOSTER_HASH) {
        selectedAddOnObj = {
            product: 'REVENUE_BOOSTER',
            planCode: addOns.revenueBooster.defaultCode,
            quantity: null,
        };
    } else if (hash === NUTSHELL_IQ_HASH) {
        selectedAddOnObj = {
            product: 'NUTSHELL_IQ',
            planCode: addOns.nutshellIq.defaultCode,
            quantity: null,
        };
    } else if (hash === LANDING_PAGES_HASH) {
        selectedAddOnObj = {
            product: 'LANDING_PAGES',
            planCode: addOns.landingPages.defaultCode,
            quantity: null,
        };
    } else if (hash === QUOTES_HASH) {
        selectedAddOnObj = {
            product: 'QUOTES',
            planCode: addOns.quotes.defaultCode,
            quantity: null,
        };
    }

    return selectedAddOnObj;
}

export function getAddOnKey(hash: string) {
    switch (hash) {
        case MARKETING_HASH:
            return 'marketing';
        case REVENUE_BOOSTER_HASH:
            return 'revenueBooster';
        case NUTSHELL_IQ_HASH:
            return 'nutshellIq';
        case LANDING_PAGES_HASH:
            return 'landingPages';
        case INBOX_HASH:
            return 'inbox';
        case SMS_CAMPAIGNS_HASH:
            return 'smsCampaigns';
        default:
            return null;
    }
}

export function getAddOnsForCurrentView(addOns: ?AddOns, currentView: string) {
    if (currentView === 'marketing') {
        return {
            marketing: addOns && addOns.marketing,
        };
    } else {
        return {
            landingPages: addOns && addOns.landingPages,
            nutshellIq: addOns && addOns.nutshellIq,
            revenueBooster: addOns && addOns.revenueBooster,
            smsCampaigns: addOns && addOns.smsCampaigns,
            quotes: addOns && addOns.quotes,
        };
    }
}
