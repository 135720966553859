/* @flow */

import './polyfills'; // We want this to run before our babel polyfill
import '@babel/polyfill';
import 'cross-fetch/polyfill';

// Global imports used primarily in legacy code
import 'visibilityjs';
import 'favico.js';

import Backbone from 'backbone';
import numeral from 'numeral';
import {Collections} from 'nutshell-core/collections';

import * as keys from 'shells/utils/keys';

import {store} from './store';
import {pollForNotifications} from './poll-for-notifications';
import * as ui from './ui/ui';
import * as Phone from './phone';

import {writeLocalStorage, readLocalStorage} from './util/localStorage';

Backbone.$ = window.jQuery;
window.numeral = numeral;

const {requestCollection, updateCollection} = Collections;

window.Nut = {
    isWebClient: true,
    actions: {
        requestCollection,
        updateCollection,
    },
    dispatch: store.dispatch.bind(store),
    keys,
    Phone,
    ui,
    writeLocalStorage,
    readLocalStorage,
    pollForNotifications,
};
