/* @flow */

import * as React from 'react';

import {safelyGetGraphQLErrorMessage} from 'nutshell-core/utils/graphql-errors';

import {TranscribeButton} from 'shells/live-transcription';

import {getUsageFromLimits} from '../limits';
import type {MonthlyUsage} from '../limits/get-usage-from-limits';
import {analyticsClient} from '../../analytics-client';
import {useGetPowerAiLiveNoteMonthlyUsage} from '../power-ai/power-ai-usage-popover/graphql/hooks';
import {ErrorDialog} from '../../client_modules/shells/react/components/dialog/error-dialog';
import {useRequestTranscribeToken} from './use-request-transcribe-token';
import {TranscribeInfoPopover} from './transcribe-info-popover';

export type TranscribeButtonContainerProps = {
    handleTranscribe: (string) => void,
    onStartTranscribe?: () => void,
    onEndTranscribe?: () => void,
    stopTranscribe?: (() => void) => void,
    eip?: Object,
    isRoutedLink?: boolean,
    location: 'timeline' | 'activity-modal' | 'email-composer',
};

function trackTranscribeFinishEvent(location: string, duration: ?number) {
    analyticsClient.track(analyticsClient.EVENTS.TRACK_TRANSCRIPTION_FINISHED, {
        location: location,
        duration,
    });
}

export function TranscribeButtonContainer(props: TranscribeButtonContainerProps) {
    const buttonRef = React.useRef<?HTMLButtonElement>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<?string>(null);
    const [isFirstTranscription, setIsFirstTranscription] = React.useState<boolean>(true);
    const [isShowingPopover, setIsShowingPopover] = React.useState<boolean>(false);
    const [isTranscribing, setIsTranscribing] = React.useState<boolean>(false);
    const [monthlyUsage, setMonthlyUsage] = React.useState<?MonthlyUsage>(null);

    const transcribeStartTimestamp = React.useRef(null);

    const requestToken = useRequestTranscribeToken();

    const usage = useGetPowerAiLiveNoteMonthlyUsage().monthlyUsage;

    const outOfCredits = monthlyUsage && monthlyUsage.remaining <= 0;

    const {stopTranscribe} = props;

    React.useEffect(() => {
        if (isTranscribing && stopTranscribe) {
            stopTranscribe(() => {
                setIsTranscribing(false);
            });
        }

        return () => {
            if (!isTranscribing && stopTranscribe) {
                stopTranscribe(() => {});
            }
        };
    }, [isTranscribing, stopTranscribe]);

    React.useEffect(() => {
        const handleEipStopWorthyEvent = () => {
            setIsTranscribing(false);
        };

        if (props.eip) {
            props.eip.container.on('EipDidClose', handleEipStopWorthyEvent);
            /* $FlowIgnore jquery function */
            props.eip.container.on('input', handleEipStopWorthyEvent);
        }

        return () => {
            if (props.eip) {
                props.eip.container.off('EipDidClose', handleEipStopWorthyEvent);
                /* $FlowIgnore jquery function */
                props.eip.container.off('input', handleEipStopWorthyEvent);
            }
        };
    }, [props.eip]);

    const handleStop = () => {
        setIsTranscribing(false);
        const duration = transcribeStartTimestamp.current
            ? Date.now() - Number(transcribeStartTimestamp.current)
            : null;
        transcribeStartTimestamp.current = null;

        trackTranscribeFinishEvent(props.location, duration);

        if (props.onEndTranscribe) {
            props.onEndTranscribe();
        }
    };

    React.useEffect(() => {
        if (isTranscribing) {
            setIsLoading(true);
            setError(null);

            requestToken()
                .then((response) => {
                    const token =
                        response &&
                        response.data &&
                        response.data.transcribeToken &&
                        response.data.transcribeToken.token;

                    const tempLimits =
                        response &&
                        response.data &&
                        response.data.transcribeToken &&
                        response.data.transcribeToken.limitInfo;
                    const tempMonthlyUsage = getUsageFromLimits(tempLimits);

                    setMonthlyUsage(tempMonthlyUsage);

                    if (!token) {
                        setIsLoading(false);
                        setIsTranscribing(false);
                        setError('There was a problem starting transcription.');
                        if (tempMonthlyUsage && tempMonthlyUsage.remaining <= 0) {
                            setIsShowingPopover(true);
                        }

                        return;
                    }

                    transcribeStartTimestamp.current = Date.now();
                    SpeechToText.start(
                        token,
                        props.handleTranscribe,
                        // startCallback
                        () => {
                            setIsLoading(false);
                            if (props.onStartTranscribe) {
                                props.onStartTranscribe();
                            }
                        },
                        // stopCallback
                        () => {
                            setIsTranscribing(false);
                        },
                        // errorCallback
                        (errorMessage) => {
                            setIsTranscribing(false);
                            setIsLoading(false);
                            setError(errorMessage || 'An error occurred while transcribing.');
                        }
                    );
                })
                .catch((e) => {
                    setIsLoading(false);
                    setIsTranscribing(false);
                    setError(
                        safelyGetGraphQLErrorMessage(e) ||
                            'There was a problem starting transcription.'
                    );
                });
        }

        return () => {
            if (isTranscribing) {
                SpeechToText.stop();
                handleStop();
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTranscribing]);

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            isFirstTranscription &&
            usage &&
            typeof usage.allTimeUsage === 'number' &&
            usage.allTimeUsage <= 0
        ) {
            setIsShowingPopover(true);
        } else if (monthlyUsage && monthlyUsage.remaining <= 0) {
            setIsShowingPopover(true);
        } else if (!isLoading) {
            setIsTranscribing(!isTranscribing);
        }
    };

    const handleConfirm = () => {
        if (isFirstTranscription) {
            setIsTranscribing(true);
            setIsFirstTranscription(false);
            setIsShowingPopover(false);

            return;
        }

        if (outOfCredits) {
            setIsShowingPopover(false);

            return;
        }

        setIsTranscribing(true);
    };

    React.useEffect(() => {
        if (!props.eip) {
            return;
        }

        props.eip.options.saveOnBlur = !isShowingPopover;

        return () => {
            if (!isShowingPopover && props.eip) {
                props.eip.options.saveOnBlur = true;
            }
        };
    }, [isShowingPopover, props.eip]);

    return (
        <>
            <TranscribeButton
                getRef={(node) => {
                    buttonRef.current = node;
                }}
                isLoading={isLoading}
                onClick={handleClick}
                isTranscribing={isTranscribing && !isLoading}
            />
            {isShowingPopover ? (
                <TranscribeInfoPopover
                    anchor={buttonRef}
                    monthlyUsage={monthlyUsage}
                    isOutOfCredits={Boolean(outOfCredits)}
                    isFirstTranscription={isFirstTranscription}
                    onConfirm={handleConfirm}
                    isRoutedLink={props.isRoutedLink}
                />
            ) : undefined}
            {error ? (
                <ErrorDialog
                    anchor={buttonRef.current}
                    location='top'
                    message={error}
                    onClose={() => setError(null)}
                />
            ) : undefined}
        </>
    );
}
