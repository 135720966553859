/* @flow */

import type {
    GetAccountBilling_accountBilling_currentPlan as AccountPlan,
    SubscriptionFragment,
} from 'nutshell-graphql-types';

export const getAddOnsIncludedInPlan = (plan: ?AccountPlan) => {
    const includedAddOns = [];
    const addOns = ['landingPages', 'nutshellIq', 'revenueBooster', 'smsCampaigns', 'quotes'];
    addOns.forEach((addOn) => {
        if (plan && plan[addOn]) {
            includedAddOns.push(plan[addOn]);
        }
    });

    return includedAddOns;
};

export const getPlanDetailText = (subscription: SubscriptionFragment) => {
    switch (subscription.product) {
        case 'CRM':
            return `${subscription.quantity.toLocaleString()} ${
                subscription.quantity === 1 ? 'user' : 'users'
            }`;
        case 'MARKETING':
            return `${subscription.productQuantity.toLocaleString()} ${
                subscription.productQuantity === 1 ? 'contact' : 'contacts'
            }`;
        case 'SMS_CAMPAIGNS':
            return `${subscription.productQuantity.toLocaleString()} ${
                subscription.productQuantity === 1 ? 'text message' : 'text messages'
            }`;
        case 'NUTSHELL_IQ':
            return `${subscription.productQuantity.toLocaleString()} ${
                subscription.productQuantity === 1 ? 'credit' : 'credits'
            }`;
        case 'INBOX':
            return `${subscription.quantity.toLocaleString()} ${
                subscription.quantity === 1 ? 'seat' : 'seats'
            }`;
        case 'QUOTES':
            return 'Entire company';
        case 'REVENUE_BOOSTER':
        case 'LANDING_PAGES':
        default:
            return null;
    }
};
