/* @flow */

import * as React from 'react';

import {pillWrapper} from '../pill-wrapper';
import Svg from './sms-credits.svg';

type Props = {
    height?: number,
    width?: number,
};

function SvgComponent(props: Props) {
    return <Svg height={props.height} width={props.width} />;
}

export const SmsCreditsPill = pillWrapper(SvgComponent);
